// services/apiservice.js

import axios from 'axios';
import { getAuth } from 'firebase/auth'; // Import Firebase Auth

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Your API base URL

// Function to get the authentication token
async function getAuthToken() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken();
  }
  return null;
}

// Fetch API Keys
export async function getApiKeys() {
  try {
    const token = await getAuthToken();
    const response = await axios.get(`${API_BASE_URL}/keys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.apiKeys;
  } catch (error) {
    console.error('Error fetching API keys:', error);
    throw new Error(error.response?.data?.error || 'Failed to fetch API keys');
  }
}

// Generate API Key
export async function generateApiKey(name) {
  try {
    const token = await getAuthToken();
    const response = await axios.post(
      `${API_BASE_URL}/keys`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error generating API key:', error);
    throw new Error(error.response?.data?.error || 'Failed to generate API key');
  }
}

// Revoke API Key
export async function revokeApiKey(keyId) {
  try {
    const token = await getAuthToken();
    const response = await axios.delete(`${API_BASE_URL}/keys/${keyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error revoking API key:', error);
    throw new Error(error.response?.data?.error || 'Failed to revoke API key');
  }
}


// Exporting processAudio as a named export using ES6 export syntax
export async function processAudio(audioUrl) {
  try {
    // Logging the audio URL to console for debugging
    console.log(`Processing audio from URL: ${audioUrl}`);

    // Simulating audio analysis results with a mock object
    const audioInfo = {
      url: audioUrl,         // URL of the audio file
      format: 'wav',         // Format of the audio file
      duration: 120.5,       // Duration in seconds
      sampleRate: 44100,     // Sample rate of the audio file
      channels: 2,           // Number of audio channels
      bitDepth: 16,          // Bit depth of the audio
      fileSize: 5242880      // File size in bytes (simulated as 5MB)
    };

    // Returning the simulated audio information
    return audioInfo;
  } catch (error) {
    // Logging any errors encountered during processing
    console.error('Error processing audio:', error);
    // Throwing an error if processing fails
    throw new Error('Failed to process audio');
  }
}
