import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
    // Optionally send error and info to an external logging service
  }

  render() {
    if (this.state.hasError) {
      // Custom error component or fallback UI
      return <h1>Something went wrong. Please try refreshing the page or contact support if the problem persists.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
